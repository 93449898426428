exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-components-about-us-js": () => import("./../../../src/pages/about-us/components/about-us.js" /* webpackChunkName: "component---src-pages-about-us-components-about-us-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-accommodations-components-accomodation-js": () => import("./../../../src/pages/accommodations/components/accomodation.js" /* webpackChunkName: "component---src-pages-accommodations-components-accomodation-js" */),
  "component---src-pages-accommodations-index-js": () => import("./../../../src/pages/accommodations/index.js" /* webpackChunkName: "component---src-pages-accommodations-index-js" */),
  "component---src-pages-home-components-alltours-js": () => import("./../../../src/pages/home/components/alltours.js" /* webpackChunkName: "component---src-pages-home-components-alltours-js" */),
  "component---src-pages-home-components-blog-js": () => import("./../../../src/pages/home/components/blog.js" /* webpackChunkName: "component---src-pages-home-components-blog-js" */),
  "component---src-pages-home-components-hero-main-js": () => import("./../../../src/pages/home/components/hero-main.js" /* webpackChunkName: "component---src-pages-home-components-hero-main-js" */),
  "component---src-pages-home-components-info-js": () => import("./../../../src/pages/home/components/info.js" /* webpackChunkName: "component---src-pages-home-components-info-js" */),
  "component---src-pages-home-components-package-js": () => import("./../../../src/pages/home/components/package.js" /* webpackChunkName: "component---src-pages-home-components-package-js" */),
  "component---src-pages-home-components-reviews-js": () => import("./../../../src/pages/home/components/reviews.js" /* webpackChunkName: "component---src-pages-home-components-reviews-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paypal-components-pay-pal-button-js": () => import("./../../../src/pages/paypal/components/PayPalButton.js" /* webpackChunkName: "component---src-pages-paypal-components-pay-pal-button-js" */),
  "component---src-pages-paypal-index-js": () => import("./../../../src/pages/paypal/index.js" /* webpackChunkName: "component---src-pages-paypal-index-js" */),
  "component---src-pages-shuttle-components-shuttleme-js": () => import("./../../../src/pages/shuttle/components/shuttleme.js" /* webpackChunkName: "component---src-pages-shuttle-components-shuttleme-js" */),
  "component---src-pages-shuttle-index-js": () => import("./../../../src/pages/shuttle/index.js" /* webpackChunkName: "component---src-pages-shuttle-index-js" */),
  "component---src-pages-tours-components-listings-js": () => import("./../../../src/pages/tours/components/listings.js" /* webpackChunkName: "component---src-pages-tours-components-listings-js" */),
  "component---src-pages-tours-index-js": () => import("./../../../src/pages/tours/index.js" /* webpackChunkName: "component---src-pages-tours-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-tourdetails-js": () => import("./../../../src/templates/tourdetails.js" /* webpackChunkName: "component---src-templates-tourdetails-js" */)
}

